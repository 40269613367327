import { Menu, X } from "lucide-react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (sectionId: string) => {
    setIsOpen(false);
    navigate("/", { replace: true });
    setTimeout(() => {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  return (
    <nav className="bg-white shadow-lg fixed w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <img
              src="favicon-48x48.png"
              alt="Manomay Homeopathy and Counselling Center Logo"
            />
            <span className="ml-2 text-xl font-semibold text-gray-800">
              Manomay Homeopathy and Counselling Center
            </span>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-8">
            <Link
              to="/"
              className="text-gray-600 hover:text-teal-600 transition-colors"
            >
              Home
            </Link>
            <button
              onClick={() => handleNavigation("about")}
              className="text-gray-600 hover:text-teal-600 transition-colors"
            >
              About
            </button>
            <button
              onClick={() => handleNavigation("treatments")}
              className="text-gray-600 hover:text-teal-600 transition-colors"
            >
              Treatments
            </button>
            <button
              onClick={() => handleNavigation("testimonials")}
              className="text-gray-600 hover:text-teal-600 transition-colors"
            >
              Testimonials
            </button>
            <button
              onClick={() => {
                window.open(
                  "https://api.whatsapp.com/send/?phone=918169782983&text&type=phone_number&app_absent=0",
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
              className="bg-teal-600 text-white px-4 py-2 rounded-md hover:bg-teal-700 transition-colors"
            >
              Book Appointment
            </button>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden flex items-center">
            <button
              aria-label="Dropdown Menu"
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-600 hover:text-teal-600 focus:outline-none"
            >
              {isOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-white">
            <Link
              to="/"
              className="block px-3 py-2 text-gray-600 hover:text-teal-600"
              onClick={() => setIsOpen(false)}
            >
              Home
            </Link>
            <button
              onClick={() => handleNavigation("about")}
              className="block w-full text-left px-3 py-2 text-gray-600 hover:text-teal-600"
            >
              About
            </button>
            <button
              onClick={() => handleNavigation("treatments")}
              className="block w-full text-left px-3 py-2 text-gray-600 hover:text-teal-600"
            >
              Treatments
            </button>
            <button
              onClick={() => handleNavigation("testimonials")}
              className="block w-full text-left px-3 py-2 text-gray-600 hover:text-teal-600"
            >
              Testimonials
            </button>
            <button
              onClick={() => {
                window.open(
                  "https://api.whatsapp.com/send/?phone=918169782983&text&type=phone_number&app_absent=0",
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
              className="w-full text-left px-3 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-700"
            >
              Book Appointment
            </button>
          </div>
        </div>
      )}
    </nav>
  );
}
