import { lazy, Suspense } from "react";
import { Loader } from "lucide-react";
import About from "../components/About";
import AwardsRecognitions from "../components/AwardsRecognitions";
import Hero from "../components/Hero";
import TreatmentList from "../components/TreatmentList";

export default function HomePage() {
  const GoogleReviews = lazy(() => import("../components/GoogleReviews"));
  const GoogleMap = lazy(() => import("../components/GoogleMap"));
  const renderLoader = () => (
    <div className="flex justify-center items-center py-8">
      <Loader className="w-8 h-8 animate-spin text-teal-600" />
    </div>
  );
  return (
    <>
      <Hero />
      <About />
      <TreatmentList />
      <AwardsRecognitions />
      <Suspense fallback={renderLoader()}>
        <GoogleReviews />
        <GoogleMap />
      </Suspense>{" "}
    </>
  );
}
