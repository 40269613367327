import { Analytics } from "@vercel/analytics/react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import HomePage from "./pages/HomePage";
import TreatmentPage from "./pages/TreatmentPage";
import Footer from "./components/Footer";
import SEO from "./components/SEO";

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className="min-h-screen">
          <SEO />
          <Navbar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/treatment/:id" element={<TreatmentPage />} />
          </Routes>
          <Analytics />
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
