import { ArrowLeft, Clock, AlertCircle, Info, Lightbulb } from "lucide-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import type { TreatmentHeader } from "../data/treatmentHeader";
import type { TreatmentContent } from "../data/treatmentContent";

interface TreatmentProps {
  header: TreatmentHeader;
  content: TreatmentContent;
}

export default function Treatment({ header, content }: TreatmentProps) {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleBack = () => {
    navigate("/");
    setTimeout(() => {
      const lastClickedId = sessionStorage.getItem("lastClickedTreatment");
      if (lastClickedId) {
        const link = document.getElementById(`treatment-link-${lastClickedId}`);
        if (link) {
          link.scrollIntoView({ behavior: "smooth", block: "center" });
          link.focus();
        }
      }
    }, 500);
  };

  const renderCallout = (type: "info" | "warning" | "tip", content: string) => {
    const icons = {
      info: <Info className="h-6 w-6 text-blue-600" />,
      warning: <AlertCircle className="h-6 w-6 text-amber-600" />,
      tip: <Lightbulb className="h-6 w-6 text-green-600" />,
    };

    const styles = {
      info: "bg-blue-50 border-blue-600 text-blue-700",
      warning: "bg-amber-50 border-amber-600 text-amber-700",
      tip: "bg-green-50 border-green-600 text-green-700",
    };

    return (
      <div className={`border-l-4 p-4 rounded-r-lg my-6 ${styles[type]}`}>
        <div className="flex items-start">
          {icons[type]}
          <p className="ml-3">{content}</p>
        </div>
      </div>
    );
  };

  return (
    <article className="max-w-4xl mx-auto px-4 py-12">
      <button
        onClick={handleBack}
        className="mb-8 inline-flex items-center text-teal-600 hover:text-teal-700"
      >
        <ArrowLeft className="h-5 w-5 mr-2" />
        Back to Treatments
      </button>

      <header className="mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">
          {content.title}
        </h1>

        <div className="flex items-center gap-6 text-gray-600 mb-6">
          <div className="flex items-center">
            <Clock className="h-5 w-5 mr-2" />
            {header.readTime}
          </div>
        </div>

        <img
          src={header.coverImage}
          alt={header.title}
          className="w-full h-[400px] object-cover rounded-xl"
        />
      </header>

      <div className="prose prose-lg max-w-none">
        <p className="text-gray-600 mb-8">{content.introduction}</p>

        {content.sections.map((section, index) => (
          <section key={index} className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              {section.title}
            </h2>
            <p className="text-gray-600 mb-4">{section.content}</p>

            {section.bulletPoints && (
              <ul className="space-y-2 mb-6">
                {section.bulletPoints.map((point, idx) => (
                  <li key={idx} className="flex items-start">
                    <span className="h-2 w-2 bg-teal-600 rounded-full mt-2 mr-3 flex-shrink-0"></span>
                    <span className="text-gray-600">{point}</span>
                  </li>
                ))}
              </ul>
            )}

            {section.timeline && (
              <div className="space-y-4 my-6">
                {section.timeline.map((item, idx) => (
                  <div key={idx} className="flex items-start">
                    <div className="flex-shrink-0 w-8 h-8 bg-teal-100 rounded-full flex items-center justify-center mr-4">
                      <span className="text-teal-600 font-semibold">
                        {idx + 1}
                      </span>
                    </div>
                    <div>
                      <h4 className="font-semibold text-gray-900">
                        {item.phase}
                      </h4>
                      <p className="text-gray-600">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {section.table && (
              <div className="overflow-x-auto my-6">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      {section.table.headers.map((header, idx) => (
                        <th
                          key={idx}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {section.table.rows.map((row, idx) => (
                      <tr key={idx}>
                        {row.map((cell, cellIdx) => (
                          <td
                            key={cellIdx}
                            className="px-6 py-4 whitespace-nowrap text-gray-600"
                          >
                            {cell}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {section.keyHighlight && (
              <div className="bg-teal-50 border-l-4 border-teal-600 p-4 rounded-r-lg my-6">
                <div className="flex items-start">
                  <AlertCircle className="h-6 w-6 text-teal-600 mr-3 flex-shrink-0 mt-1" />
                  <div>
                    <h4 className="text-lg font-semibold text-teal-900 mb-1">
                      {section.keyHighlight.title}
                    </h4>
                    <p className="text-teal-700">
                      {section.keyHighlight.description}
                    </p>
                  </div>
                </div>
              </div>
            )}

            {section.callout &&
              renderCallout(section.callout.type, section.callout.content)}
          </section>
        ))}

        <section className="mb-12">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Conclusion</h2>
          <p className="text-gray-600">{content.conclusion}</p>
        </section>
      </div>

      <button
        onClick={handleBack}
        className="mb-8 inline-flex items-center text-teal-600 hover:text-teal-700"
      >
        <ArrowLeft className="h-5 w-5 mr-2" />
        Back to Treatments
      </button>

    </article>
  );
}
