import { useParams } from 'react-router-dom';
import { treatmentContents } from '../data/treatmentContent';
import { treatmentHeaders } from '../data/treatmentHeader';
import Treatment from '../components/Treatment';

export default function TreatmentPage() {
  const { id } = useParams();
  const header = treatmentHeaders.find(p => p.id === id);
  const content = id ? treatmentContents[id] : null;

  if (!header || !content) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900">Treatment not found</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-16 min-h-screen bg-gray-50">
      <Treatment header={header} content={content} />
    </div>
  );
}