export interface TreatmentContent {
  id: string;
  title: string;
  introduction: string;
  sections: {
    title: string;
    content: string;
    bulletPoints?: string[];
    keyHighlight?: {
      title: string;
      description: string;
    };
    timeline?: {
      phase: string;
      description: string;
    }[];
    table?: {
      headers: string[];
      rows: string[][];
    };
    callout?: {
      type: 'info' | 'warning' | 'tip';
      content: string;
    };
  }[];
  conclusion: string;
}

export const treatmentContents: Record<string, TreatmentContent> = {
  'managing-chronic-illness': {
    id: 'managing-chronic-illness',
    title: 'Managing Chronic Illness: A Natural Path to Wellness',
    introduction: 'Living with a chronic illness presents unique challenges that require a comprehensive and personalized approach to healing. Homeopathy offers a gentle yet effective path to managing chronic conditions while supporting the body\'s natural healing processes.',
    sections: [
      {
        title: 'Understanding Chronic Illness',
        content: 'Chronic illnesses are ongoing conditions that require continuous management and attention.',
        timeline: [
          {
            phase: 'Generalized Anxiety Disorder (GAD)',
            description: 'A disorder characterized by excessive, uncontrollable worry about everyday situations'
          },
          {
            phase: 'Major Depressive Disorder (MDD)',
            description: 'A mood disorder characterized by persistent feelings of sadness, hopelessness, and loss of interest or pleasure in activities. It affects Mind as well as Body.'
          },
          {
            phase: 'Chronic Insomnia/ Sleep Disorder',
            description: 'A sleep disorder characterized by difficulty falling asleep or staying asleep, which can lead to daytime fatigue and mood disturbances.'
          },
          {
            phase: 'Post-Traumatic Stress Disorder (PTSD)',
            description: 'A condition triggered by experiencing or witnessing a traumatic event. Symptoms include flashbacks, nightmares, and heightened anxiety.'
          }, 
          {
            phase: 'Personality Disorders',
            description: 'These disorders involve enduring patterns of behavior, cognition, and inner experience that deviate markedly from the expectations of the individual\'s culture. Borderline Personality Disorder (BPD) and Antisocial Personality Disorder (ASPD) are examples.'
          },
          {
            phase: 'Addictions',
            description: 'Addiction is a multifaceted condition that affects individuals physically, psychologically, and socially. There are different types of Addictions such as Alcohol, opioid, cigarette, mobile. Addictions often co-occur with other mental health disorders, such as anxiety, depression, or trauma-related disorders.'
          },
          {
            phase: 'Schizophrenia',
            description: 'A serious, long-term mental disorder that affects a person\'s thoughts, emotions, and behaviors. Individuals may experience hallucinations, delusions, and cognitive impairments.'
          },
          {
            phase: 'Bipolar Disorder',
            description: 'A mood disorder characterized by extreme mood swings that include emotional highs (mania or hypomania) and lows (depression).'
          }, 
          {
            phase: 'Obsessive-Compulsive Disorder (OCD)',
            description: 'A disorder marked by intrusive, unwanted thoughts (obsessions) and repetitive behaviors (compulsions) performed to alleviate anxiety.'
          },
          {
            phase: 'Eating Disorders',
            description: 'Disorders characterized by abnormal eating habits that may involve overeating or undereating, such as anorexia nervosa, bulimia nervosa, or binge-eating disorder.'
          }
        ], 
        keyHighlight: {
          title: 'Did You Know?',
          description: '60% of adults lives with Stress, making it a significant health concern in daily life.'
        }
      },
      {
        title: 'Common Chronic Disorders',
        content: 'Homeopathy views chronic illness as a disruption in the body\'s vital force, requiring individualized treatment.',
        bulletPoints: [
          'Constitutional treatment approach',
          'Focus on root causes rather than symptoms',
          'Consideration of mental and emotional factors',
          'Gentle, non-suppressive healing process'
        ]
      },
      {
        title: 'Natural Management Strategies',
        content: 'Effective management of chronic illness involves multiple complementary approaches:',
        bulletPoints: [
          'Dietary modifications and nutritional support',
          'Stress management and relaxation techniques',
          'Regular, appropriate physical activity',
          'Sleep optimization',
          'Environmental modifications',
          'Social support systems'
        ]
      },
      {
        title: 'Common Homeopathic Remedies',
        content: 'While treatment must be individualized, certain remedies are frequently indicated in chronic conditions:',
        bulletPoints: [
          'Arsenicum Album - For anxiety and restlessness',
          'Phosphorus - For fatigue and weakness',
          'Lycopodium - For digestive issues',
          'Calcarea Carbonica - For slow recovery and weakness'
        ],
        keyHighlight: {
          title: 'Important Note',
          description: 'Always consult a qualified homeopath for proper remedy selection and dosing.'
        }
      }
    ],
    conclusion: 'Managing chronic illness through homeopathy offers a promising path to improved health and well-being. By addressing both physical symptoms and underlying causes while supporting the body\'s natural healing mechanisms, homeopathy provides a comprehensive approach to chronic illness management.'
  },
  'psychiatric-evaluations': {
    id: 'psychiatric-evaluations',
    title: 'Understanding Psychiatric Evaluations and Diagnoses',
    introduction: 'Psychiatric evaluations are comprehensive assessments that help understand a person\'s mental health status. This guide explores how homeopathic practitioners integrate traditional psychiatric evaluation methods with holistic healing approaches.',
    sections: [
      {
        title: 'The Evaluation Process',
        content: 'A psychiatric evaluation is a structured assessment process that examines various aspects of mental health.',
        timeline: [
          {
            phase: 'Initial Assessment',
            description: 'Gathering basic information and establishing rapport'
          },
          {
            phase: 'Detailed History',
            description: 'Exploring personal, family, and medical history'
          },
          {
            phase: 'Mental Status Examination',
            description: 'Assessing current mental state and cognitive function'
          },
          {
            phase: 'Diagnostic Formulation',
            description: 'Synthesizing information to form a comprehensive understanding'
          }
        ]
      },
      {
        title: 'Key Components of Evaluation',
        content: 'A thorough psychiatric evaluation includes several essential elements:',
        table: {
          headers: ['Component', 'Purpose', 'Significance'],
          rows: [
            ['Clinical Interview', 'Gather detailed information', 'Foundation of assessment'],
            ['Mental Status Exam', 'Evaluate current functioning', 'Immediate health status'],
            ['Physical Assessment', 'Check for medical factors', 'Rule out physical causes'],
            ['Psychological Testing', 'Objective measurement', 'Quantifiable data']
          ]
        }
      },
      {
        title: 'Homeopathic Integration',
        content: 'Homeopathic practitioners enhance traditional psychiatric evaluations by:',
        bulletPoints: [
          'Considering the whole person, not just symptoms',
          'Evaluating emotional and physical symptoms together',
          'Assessing constitutional type',
          'Looking for patterns in symptoms and reactions'
        ],
        callout: {
          type: 'info',
          content: 'Homeopathic evaluation adds depth to traditional psychiatric assessment by considering subtle aspects of personality and constitution.'
        }
      }
    ],
    conclusion: 'Integrating homeopathic principles with psychiatric evaluations provides a more comprehensive understanding of mental health, leading to more effective and personalized treatment approaches.'
  },
  'holistic-counselling': {
    id: 'holistic-counselling',
    title: 'Holistic Counselling: Integrating Mind and Body',
    introduction: 'Holistic counselling represents a unique approach that combines traditional therapeutic techniques with natural healing principles. This comprehensive guide explores how homeopathic counselling can address both mental and physical aspects of well-being.',
    sections: [
      {
        title: 'Principles of Holistic Counselling',
        content: 'Holistic counselling is founded on the understanding that mental, physical, and spiritual well-being are interconnected.',
        bulletPoints: [
          'Integration of mind-body healing approaches',
          'Recognition of individual uniqueness',
          'Focus on root causes rather than symptoms',
          'Emphasis on natural healing processes'
        ],
        keyHighlight: {
          title: 'Core Philosophy',
          description: 'Every person has innate healing capabilities that can be activated through proper support and guidance.'
        }
      },
      {
        title: 'Therapeutic Techniques',
        content: 'Holistic counselling employs various techniques to support healing:',
        table: {
          headers: ['Technique', 'Purpose', 'Benefits'],
          rows: [
            ['Mindfulness Practice', 'Present moment awareness', 'Reduced anxiety and stress'],
            ['Rational Emotional Behavioral (REBT)', 'Changing Negative thought pattern', 'Emotional freedom'],
            ['Body Awareness', 'Physical-emotional connection', 'Improved self-understanding'],
            ['Dialectical Behavior Therapy (DBT)', 'Supporting Problem solving process', 'Helps in relationships'],
            ['Exposure Therapy', 'To overcome fear', 'Reduced fear and phobia'],
            ['Psychotherapy (Talk Therapy)', 'Helps to relieve emotional burden', 'Improved emotional regulation']
          ]
        }
      },
      {
        title: 'Integration with Homeopathy',
        content: 'Homeopathic principles enhance counselling through:',
        bulletPoints: [
          'Constitutional remedy support',
          'Emotional balancing remedies',
          'Crisis management solutions',
          'Long-term healing protocols'
        ],
        callout: {
          type: 'tip',
          content: 'The combination of counselling and homeopathy can provide deeper and more lasting results than either approach alone.'
        }
      }
    ],
    conclusion: 'Holistic counselling offers a comprehensive approach to mental health that honors the whole person. By integrating traditional counselling with homeopathic principles, it provides a path to deeper healing and lasting transformation.'
  },
  'somatic-psychic-disorders': {
    id: 'somatic-psychic-disorders',
    title: 'Understanding Somatic-Psychic Disorders',
    introduction: 'Somatic-psychic disorders represent a complex intersection of physical and psychological symptoms. This comprehensive exploration reveals how homeopathic approaches can address both aspects simultaneously.',
    sections: [
      {
        title: 'Understanding the Mind-Body Connection',
        content: 'Somatic-psychic disorders demonstrate the intricate relationship between mental and physical health.',
        timeline: [
          {
            phase: 'Trigger Event',
            description: 'Initial psychological or physical stress'
          },
          {
            phase: 'Manifestation',
            description: 'Development of physical symptoms'
          },
          {
            phase: 'Psychological Impact',
            description: 'Emotional response to symptoms'
          },
          {
            phase: 'Cycle Continuation',
            description: 'Reinforcement of mind-body connection'
          }
        ],
        keyHighlight: {
          title: 'Critical Understanding',
          description: 'The mind-body connection in somatic-psychic disorders is bidirectional, with each aspect influencing the other.'
        }
      },
      {
        title: 'Common Manifestations',
        content: 'Somatic-psychic disorders can present in various ways:',
        bulletPoints: [
          'Chronic pain without clear physical cause',
          'Digestive issues linked to anxiety',
          'Skin conditions triggered by stress',
          'Fatigue associated with depression'
        ],
        callout: {
          type: 'warning',
          content: 'Always seek professional evaluation to rule out underlying physical conditions.'
        }
      },
      {
        title: 'Homeopathic Treatment Approach',
        content: 'Homeopathy offers unique advantages in treating somatic-psychic disorders:',
        table: {
          headers: ['Aspect', 'Approach', 'Benefits'],
          rows: [
            ['Constitutional Treatment', 'Whole person healing', 'Addresses root causes'],
            ['Acute Remedies', 'Symptom relief', 'Immediate comfort'],
            ['Mental Support', 'Emotional balance', 'Psychological healing'],
            ['Physical Support', 'Body healing', 'Symptom resolution']
          ]
        }
      }
    ],
    conclusion: 'Understanding and treating somatic-psychic disorders requires a sophisticated approach that addresses both mental and physical aspects. Homeopathy\'s holistic nature makes it particularly well-suited for this complex challenge.'
  },
  'medication-management': {
    id: 'medication-management',
    title: 'Natural Approaches to Medication Management',
    introduction: 'Effective medication management in homeopathy involves a careful balance of traditional and natural approaches. This guide explores how to optimize medication use while incorporating homeopathic principles.',
    sections: [
      {
        title: 'Principles of Natural Medication Management',
        content: 'Understanding the core principles of natural medication management is essential for optimal results.',
        bulletPoints: [
          'Individualized treatment protocols',
          'Minimal intervention approach',
          'Focus on supporting natural healing',
          'Integration with conventional medicine when necessary'
        ],
        keyHighlight: {
          title: 'Safety First',
          description: 'Natural approaches should complement, not replace, necessary conventional medications without professional guidance.'
        }
      },
      {
        title: 'Homeopathic Integration Strategies',
        content: 'Strategic integration of homeopathic remedies with conventional medications requires careful consideration.',
        table: {
          headers: ['Strategy', 'Purpose', 'Considerations'],
          rows: [
            ['Timing Separation', 'Optimal absorption', 'Spacing between medicines'],
            ['Compatibility Check', 'Avoid interactions', 'Professional guidance'],
            ['Monitoring', 'Track effectiveness', 'Regular assessment'],
            ['Adjustment Protocol', 'Optimize results', 'Gradual changes']
          ]
        },
        callout: {
          type: 'warning',
          content: 'Never adjust conventional medications without consulting your healthcare provider.'
        }
      },
      {
        title: 'Natural Support Methods',
        content: 'Supporting medication management with natural methods:',
        bulletPoints: [
          'Dietary modifications to enhance medication effectiveness',
          'Lifestyle adjustments for optimal results',
          'Stress management techniques',
          'Sleep optimization strategies'
        ]
      }
    ],
    conclusion: 'Successful medication management combines the best of both natural and conventional approaches. Through careful integration and professional guidance, patients can achieve optimal results while minimizing potential side effects.'
  },
  'child-adolescent-care': {
    id: 'child-adolescent-care',
    title: 'Children & Adolescent Psychiatric Care: A Gentle Approach',
    introduction: 'Providing psychiatric care for young people requires special consideration and gentle approaches. This guide explores how homeopathy can offer safe and effective support for children and adolescents.',
    sections: [
      {
        title: 'Developmental Considerations',
        content: 'Understanding developmental stages is crucial for effective treatment.',
        timeline: [
          {
            phase: 'Early Childhood',
            description: 'Focus on emotional security and attachment'
          },
          {
            phase: 'Middle Childhood',
            description: 'Building social skills and self-esteem'
          },
          {
            phase: 'Early Adolescence',
            description: 'Managing hormonal and emotional changes'
          },
          {
            phase: 'Late Adolescence',
            description: 'Developing identity and independence'
          }
        ],
        keyHighlight: {
          title: 'Critical Period',
          description: 'Early intervention during developmental stages can prevent long-term mental health challenges.'
        }
      },
      {
        title: 'Common Challenges',
        content: 'Addressing typical mental health challenges in young people:',
        table: {
          headers: ['Challenge', 'Symptoms', 'Approach'],
          rows: [
            ['Anxiety', 'Worry, physical symptoms', 'Gentle calming remedies'],
            ['Depression', 'Mood changes, withdrawal', 'Constitutional support'],
            ['ADHD', 'Focus issues, hyperactivity', 'Balanced treatment'],
            ['Behavioral Issues', 'Acting out, aggression', 'Individual protocols']
          ]
        },
        callout: {
          type: 'info',
          content: 'Each child requires an individualized approach based on their unique presentation and circumstances.'
        }
      },
      {
        title: 'Homeopathic Support Strategies',
        content: 'Gentle approaches for supporting young mental health:',
        bulletPoints: [
          'Age-appropriate remedies and dosing',
          'Integration with family therapy',
          'Support for school and social situations',
          'Crisis management protocols'
        ]
      }
    ],
    conclusion: 'Children and adolescent psychiatric care through homeopathy offers a gentle, effective approach to supporting young mental health. By considering developmental stages and individual needs, practitioners can help young people navigate mental health challenges successfully.'
  }
};