export const awards = [
  {
    id: 1,
    title: "Excellence in Homeopathy",
    organization: "International Homeopathy Association",
    year: "2023",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/avishkar-certificate.jpg",
  },
  {
    id: 2,
    title: "Best Holistic Care Provider",
    organization: "Natural Health Foundation",
    year: "2022",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/avishkar-inter-university-certificate.jpg",
  },
  {
    id: 3,
    title: "Research Excellence Award",
    organization: "Global Homeopathy Research Council",
    year: "2021",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/avishkar-presentation-one.jpeg",
  },
  {
    id: 4,
    title: "Lifetime Achievement Award",
    organization: "National Homeopathy Board",
    year: "2020",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/avishkar-presentation-two.jpeg",
  },
  {
    id: 5,
    title: "Excellence in Homeopathy",
    organization: "International Homeopathy Association",
    year: "2023",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/awards-one.jpeg",
  },
  {
    id: 6,
    title: "Best Holistic Care Provider",
    organization: "Natural Health Foundation",
    year: "2022",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/baecan-case-competition.jpg",
  },
  {
    id: 7,
    title: "Research Excellence Award",
    organization: "Global Homeopathy Research Council",
    year: "2021",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/baecon-foundation-certificate.jpg",
  },
  {
    id: 8,
    title: "Lifetime Achievement Award",
    organization: "National Homeopathy Board",
    year: "2020",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/baecon-foundation.jpeg",
  },
  {
    id: 9,
    title: "Excellence in Homeopathy",
    organization: "International Homeopathy Association",
    year: "2023",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/international-conference.jpeg",
  },
  {
    id: 10,
    title: "Best Holistic Care Provider",
    organization: "Natural Health Foundation",
    year: "2022",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/interview.jpeg",
  },
  {
    id: 11,
    title: "Research Excellence Award",
    organization: "Global Homeopathy Research Council",
    year: "2021",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/linkedin-post.jpeg",
  },
  {
    id: 12,
    title: "Lifetime Achievement Award",
    organization: "National Homeopathy Board",
    year: "2020",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/mld-trust-one.jpeg",
  },
  {
    id: 13,
    title: "Excellence in Homeopathy",
    organization: "International Homeopathy Association",
    year: "2023",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/mld-trust-two.jpeg",
  },
  {
    id: 14,
    title: "Best Holistic Care Provider",
    organization: "Natural Health Foundation",
    year: "2022",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/presentation-one.jpeg",
  },
  {
    id: 15,
    title: "Research Excellence Award",
    organization: "Global Homeopathy Research Council",
    year: "2021",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/presentation-two.jpeg",
  },
  {
    id: 16,
    title: "Lifetime Achievement Award",
    organization: "National Homeopathy Board",
    year: "2020",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/presentation-three.jpeg",
  },
  {
    id: 17,
    title: "Excellence in Homeopathy",
    organization: "International Homeopathy Association",
    year: "2023",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/presentation-four.jpeg",
  },
  {
    id: 18,
    title: "Best Holistic Care Provider",
    organization: "Natural Health Foundation",
    year: "2022",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/presentation-five.jpeg",
  },
  {
    id: 19,
    title: "Research Excellence Award",
    organization: "Global Homeopathy Research Council",
    year: "2021",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/presentation-six.jpeg",
  },
  {
    id: 20,
    title: "Lifetime Achievement Award",
    organization: "National Homeopathy Board",
    year: "2020",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/samajik-nyay.jpeg",
  },
  {
    id: 21,
    title: "Best Holistic Care Provider",
    organization: "Natural Health Foundation",
    year: "2022",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/visit-one.jpeg",
  },
  {
    id: 22,
    title: "Research Excellence Award",
    organization: "Global Homeopathy Research Council",
    year: "2021",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/visit-two.jpeg",
  },
  {
    id: 23,
    title: "Lifetime Achievement Award",
    organization: "National Homeopathy Board",
    year: "2020",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/visit-three.jpeg",
  },
];
